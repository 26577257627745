$(function () { 

    $('.js-city-service-slider').slick({
        dots: true,
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
    });

});
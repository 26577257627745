$(function () {
    if (window.matchMedia("(max-width: 1350px)").matches) {
        $('.js-feature-slider').slick({
            dots: false,
            arrows: false,
            centerPadding: '12px',
            infinite: false,
            // slidesToShow: 1,
            // centerMode: true,
            variableWidth: true,
            slidesToScroll: 1
        });
    };
});
$(function() {
// debugger;

    const calcRepairEl = document.querySelector('#calc-repair');

    if (!calcRepairEl) return;

    Vue.component('v-select', VueSelect.VueSelect);

    const MAX_STEP = 2;

    const app = new Vue({
        el: '#calc-repair',
        components: {
            VueSlider: window['vue-slider-component'],
            VueSlickCarousel: window['vue-slick-carousel'],
        },
        data: {
            carScheme: {},
            calcresult: null,
            bodyType: '',
            selectedParts: [],
            partInEdit: null,
            step: 1,
            subStep: 1,
            brands: [],
            brand: null,
            
            models: [],
            model: null,

            isFrontView: true,
        },
        methods: {
            openSubscribe: function () {
                $('#online-subscribe').slideToggle();

                var offset = window.matchMedia("(max-width: 768px)").matches ? 70 : 60;
                this.scrollToElement('#online-subscribe', offset, 800);
            },
            setFrontView: function (value) {
                this.isFrontView = value;
            },
            nextStep: function () {
                if (this.step < MAX_STEP)
                    this.step += 1;

                if (this.step == 2 ) {
                    const offset = window.matchMedia("(max-width: 768px)").matches ? 80: 100;
                    this.scrollToElement('#calc-repair', offset, 800);
                    window["calc-repair-brand"] = this.brand;
                }
            },
            prevStep: function () {
                this.returnToWorkSelection();
                if (this.step > 1)
                    this.step -= 1;
            },
            scrollToSelect: function () {
                const offset = window.matchMedia("(max-width: 768px)").matches ? 70: 90;
                this.scrollToElement('#selectBlock', offset, 800);
            },
            partClick: function(partId, e) {
                this.returnToWorkSelection();
                
                if (window.matchMedia("(max-width: 768px)").matches && this.partInEdit && this.partInEdit.id === partId) {
                    this.partInEdit = null;
                    return;
                }


                if (this.partInEdit ) {
                    const that = this;
                    let selectedPartInEdit = this.selectedParts.find(function (part) { return part.id === that.partInEdit.id });    

                    if (selectedPartInEdit || 
                        !selectedPartInEdit && 
                        this.partInEdit.elements.length &&
                        this.partInEdit.elements.find(function (elem) { return elem.inCalc; })) { // если есть что добавлять и не было раньше или был ранее выбран
                            this.addPartInEdit(false);
                    }
                }


                let selectedPart = this.selectedParts.find(function (part) { return part.id === partId });

                if (selectedPart) {
                    this.partInEdit = JSON.parse(JSON.stringify(selectedPart));
                } else {
                    let schemePart = this.carScheme.parts.find(function (part) { return part.id === partId; });

                    this.partInEdit = {
                        id: partId,
                        title: schemePart.title,
                        elements: []
                    };

                    if (schemePart.elements.length === 1) {
                        this.selectElement(schemePart.elements[0]);
                    }

                }

                
                if (window.matchMedia("(max-width: 768px)").matches) {
                    this.scrollToElement('#partlist', 80, 800);
                }
            },
            isPartSelected: function (partId) {
                return this.partInEdit && this.partInEdit.id === partId
                    || this.selectedParts.find(function (part) { 
                        return part.id === partId && part.elements.find(function (elem) { return elem.inCalc; }); 
                    });
            },
            clearPartInEdit: function () {
                this.partInEdit = null;
            },
            addPartInEdit: function (needScroll) {
                const that = this;
                let selectedPartIndex = this.selectedParts.findIndex(function (part) { return part.id === that.partInEdit.id });
                if (selectedPartIndex !== -1) {
                    if (this.partInEdit.elements.length) {
                        this.selectedParts[selectedPartIndex] = this.partInEdit;
                    } else {
                        this.selectedParts.splice(selectedPartIndex, 1);
                    }
                } else {
                    if (this.partInEdit.elements.length) {
                        this.selectedParts.push(this.partInEdit);
                    }
                }

                this.clearPartInEdit();

                if (needScroll) {
                    this.scrollToElement('#step2Container', 93, 800);
                }
            },

            deleteSelectedElement: function (part, selectedElement) {                
                this.returnToWorkSelection();

                let selectedElementIndex = part.elements.findIndex(function (element) { return element.id === selectedElement.id });
                if (selectedElementIndex !== -1) {
                    part.elements.splice(selectedElementIndex, 1);
                } 

                if (!part.elements.length) { // не осталось элементов => удалить саму часть
                    let selectedPartIndex = this.selectedParts.findIndex(function (p) { return p.id === part.id });
                    if (selectedPartIndex !== -1) {
                        this.selectedParts.splice(selectedPartIndex, 1);
                    } 
                }

            },
            selectElement: function (elementScheme) {
                let isRemoved = app.removeElement(elementScheme);
                if (! isRemoved) {
                    let damage = null;
                    let type = null;

                    if (elementScheme.types && elementScheme.types.length)
                        type = elementScheme.types[0];

                    if (type && type.damage && type.damage.length)
                        damage = type.damage[0];

                    if (!damage && elementScheme.damage && elementScheme.damage.length)
                        damage = elementScheme.damage[0];

                    this.partInEdit.elements.push({
                        id: elementScheme.id,
                        title: elementScheme.title,
                        type: type,
                        damage: damage,
                        damageId: damage ? damage.id : 0,
                        inCalc: false
                    });
                }
            },
            isElementSelected: function (elementScheme) {
                return this.partInEdit && 
                    this.partInEdit.elements.find(function (element) { return element.id === elementScheme.id; });
            },
            removeElement: function (elementScheme) {
                let selectedElementIndex = this.partInEdit.elements.findIndex(function (element) { return element.id === elementScheme.id });
                if (selectedElementIndex !== -1) {
                    this.partInEdit.elements.splice(selectedElementIndex, 1);
                    return true
                }
                return false
            },
            addToCalc: function (elementScheme) {
                let selectedElementIndex = this.partInEdit.elements.findIndex(function (element) { return element.id === elementScheme.id });
                if (selectedElementIndex !== -1) {
                    this.partInEdit.elements[selectedElementIndex].inCalc = true;
                }    
            },
            removeFromCalc: function (elementScheme) {
                let selectedElementIndex = this.partInEdit.elements.findIndex(function (element) { return element.id === elementScheme.id });
                if (selectedElementIndex !== -1) {
                    this.partInEdit.elements[selectedElementIndex].inCalc = false;
                }    
            },
            getSelectedElementByScheme: function (elementScheme) {
                let selectedElementIndex = this.partInEdit.elements.findIndex(function (element) { return element.id === elementScheme.id });
                if (selectedElementIndex !== -1) {
                    return this.partInEdit.elements[selectedElementIndex];
                }
            },
            damageChange: function (elementScheme, damageId) {
                const selectedElement = this.getSelectedElementByScheme(elementScheme);
                let damage;
                
                if (selectedElement.type) {
                    damage = selectedElement.type.damage.find(function (d) { return d.id === damageId; });
                } else {
                    damage = elementScheme.damage.find(function (d) { return d.id === damageId; });
                }

                if (selectedElement && damage) {
                    selectedElement.damage = damage;
                }
            },
            typeChange: function (element, evt) {
                const selectedElement = this.getSelectedElementByScheme(element);
                selectedElement.damage = selectedElement.type.damage[0];
                selectedElement.damageId = selectedElement.type.damage[0].id;
            },
            calculatePrice: function () {

                let selectedElemets = [];

                this.selectedParts.forEach(function (selectedPart) {
                    selectedPart.elements.forEach(function (element) {
                        if (!element.inCalc)
                            return;

                        if (element.type) {
                            selectedElemets.push({
                                id: element.type.id,
                                damageId: element.damage.id
                            });
                        } else {
                            selectedElemets.push({
                                id: element.id,
                                damageId: element.damage.id
                            });
                        }
                    });
                });

                // собрать объект
                const calcData = {
                    brandId: this.brand.id,
                    modelId: this.model.id,
                    elements: selectedElemets
                };

                // отправить
                const rootElement = document.getElementById('calc-repair');
                const that = this;

                axios
                    .post(rootElement.dataset.calcresult, calcData)
                    .then(function(response) {
                        // обработать ответ
                        that.calcresult = response.data.data;

                        that.subStep = 2;
                    }).catch(function (error) {
                        console.log(error);
                    });

                this.scrollToElement('#selected-works', 40, 800);
            },
            returnToWorkSelection: function () {
                this.subStep = 1;
            },
            getElementPrice: function (selectedElement) {
                const elementId = selectedElement.type ? selectedElement.type.id : selectedElement.id;
                const priceElement = this.calcresult ? this.calcresult.find(function (d) { return d.id === elementId; }) : 0;

                return priceElement ? priceElement.price : 0;
            },
            showNext: function () {
                this.$refs.carousel.next();
            },
            showPrev: function () {
                this.$refs.carousel.prev();
            },
            addNcalc: function () {
                this.addPartInEdit();
                this.calculatePrice();
            },
            scrollToElement: function (elementSelector, offset, duration) {
                const offsetTop = offset || 0;
                const durationSpeed = duration || 500;

                setTimeout(function () {
                    $('html, body').animate({
                        scrollTop: $(elementSelector).offset().top - offsetTop
                    }, durationSpeed);    
                }, 50);
            }
        },
        computed: {
            modelsByBrand: function () {
                const selectedBrand = this.brand;
                if (selectedBrand)
                    return this.models.filter(function (model) { return model.brandId === selectedBrand.id; });
                else
                    return [];
            },
            bodyTypeTitle: function () {
                const typeNames = {
                    sedan: 'Седан',
                    hatchback: 'Хетчбек',
                    universal: 'Универсал',
                    SUV: 'Внедорожник',
                };

                return this.bodyType ? typeNames[this.bodyType] : '';
            },
            partInEditScheme: function () {
                const that = this;
                return this.carScheme.parts.find(function (part) { return part.id === that.partInEdit.id });
            },
            isStep2Available: function () {
                return this.bodyType && this.brand && this.model;
            },
            totalSum: function () {
                let sum = 0;
                if (this.calcresult) {
                    this.calcresult.forEach(function (item) {
                        sum += item.price;
                    });
                }
                return sum;
            },
            hasSelectedEl: function () {
                return this.partInEdit.elements.length || this.selectedParts.length;
            }
        },

        created: function () {
            const rootElement = document.getElementById('calc-repair');

            axios
                .get(rootElement.dataset.brandsurl)
                .then(response => (this.brands = response.data.data));
            axios
                .get(rootElement.dataset.modelsurl)
                .then(response => (this.models = response.data.data));
            axios
                .get(rootElement.dataset.carschemeurl)
                .then(response => (this.carScheme = response.data.data));
            $('.calc-repair__block').show();
        }
    })

})
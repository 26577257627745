$(function() {

    const rootElement = document.getElementById('service-search');
    if (!rootElement) return;

    Vue.component('v-select', VueSelect.VueSelect);

    const app = new Vue({
        el: '#service-search',
        data: {
            service_search: "",
            dataServiceList: [],
        },

        created: function() {
            const dataServiceList = $("#service-search").data("services")
            if (!dataServiceList) return
            axios.get(dataServiceList)
            .then((res) => {
                this.dataServiceList = res.data.result
            });
        },

        
        watch: {
            service_search: function(e) {
                window.location.href = e.link;
            }
        }
    })
});
$(function() {
    if ($('.main__bottom').length) {
        $('.main__bottom').css('height', $('.main__catalog').innerHeight() + 'px');
    }
    
    $(document).on('click', '.main__catalog-link_all', function(e){
        e.preventDefault();
        let wrap = $(this).parent();
        wrap.toggleClass('main__catalog_all');
    });

    // ----------------------

    let $barandSlider = $('.js-brand-slider')

    $barandSlider.slick({
        slidesToShow: 12,
        speed: 200, 
        autoplay: true, 
        pauseOnHover: true,
        slidesToScroll: 0.1,
        autoplaySpeed: 0, 
        cssEase: 'linear', 
        arrows: false,
        infinite: true,
        swipe: false,
        responsive: [
            {
              breakpoint: 1350,
              settings: {
                slidesToShow: 10,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 8,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
              }
            }
          ]
    });

    // ----------------------------

    $(document).on('click', '.js-collapsing-section', function () {

      let $header = $(this);        
      let $parent = $header.parent();
      $parent.toggleClass('show')
      let $content = $header.next();
      $content.slideToggle(500);
        
    });

})
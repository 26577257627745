



$(function() {
    $('body').on('mouseover','.service-scheme__link,.service-scheme__dot',function(){
        $('.service-scheme__dot,.service-scheme__link').removeClass('is-hover');
        $('.service-scheme__dot[data-id="'+$(this).data('id')+'"],.service-scheme__link[data-id="'+$(this).data('id')+'"]').addClass('is-hover');
    });
    $('body').on('click','.service-scheme__link,.service-scheme__dot',function(){
        var id = $(this).data('id');
        if (!id) {
            id = $(this).parent().data('id')
        }
        $('.service-scheme__right span').fadeOut(5).removeClass('active');
        $('.service-scheme__dot,.service-scheme__link,.service-scheme__right span').removeClass('is-hover');
        $('.service-scheme__dot[data-id="'+id+'"],.service-scheme__link[data-id="'+id+'"]').addClass('is-hover');
        $('.service-scheme__right span[data-id="'+id+'"]').fadeIn(50).addClass('active');

    });
    $('body').on('mouseleave','.service-scheme__left,.service-scheme__img',function(){
        $('.service-scheme__dot,.service-scheme__link').removeClass('is-hover');
        var id =  $('.service-scheme__right span.active').data('id');
        $('.service-scheme__dot[data-id="'+id+'"],.service-scheme__link[data-id="'+id+'"]').addClass('is-hover');
    });
})
import "%modules%/header/header";
import "%modules%/nav/nav";
import "%modules%/form/form";
import "%modules%/reviews/reviews";
import "%modules%/card/card";
import "%modules%/special/special";
import "%modules%/products/products";
import "%modules%/wecan/wecan";
import "%modules%/user-account/user-account";
//import "%modules%/order/order";
import "%modules%/main/main";
import "%modules%/modal/modal";
//import "%modules%/cookies/cookies";
import "%modules%/feature/feature";
import "%modules%/video/video";
import "%modules%/more-articles/more-articles";
import "%modules%/other-categories/other-categories";
import "%modules%/survey/survey";
import "%modules%/mesengers/mesengers";

import "%modules%/calc-repair/calc-repair";
import "%modules%/collapse/collapse";

// раскомментировать при правках верстки блока, при сборке на бой оставлять закомментированным
//import "%modules%/online-register-main/online-register-main";
import "%modules%/online-register/online-register";
//import "%modules%/calculator/calculator";

import "%modules%/service-search/service-search";
import "%modules%/anchor/anchor";
import "%modules%/service-card/service-card";
import "%modules%/about/about";

import "%modules%/stage/stage";
import "%modules%/rating-form/rating-form";
import "%modules%/datepicker/datepicker";
import "%modules%/slider/slider";
import "%modules%/certificates/certificates";
import "%modules%/service-scheme/service-scheme";
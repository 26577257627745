$(function() {
    const $window = $(window);
    const $serviceCard = $(".js-service-card");

    const titleLinks = $serviceCard.find(".service-card__item");

    let widthItems = 0
    const widthArr = []

    titleLinks.each(function(i, item) {
        widthItems = item.clientWidth + widthItems 
        if (!!(i % 2)) {
            widthArr.push(widthItems)
            widthItems = 0;
        }
    })
    
    const maxWidth = Math.max(...widthArr);
    
    setWidthServiceCard()

    $window.on("resize", setWidthServiceCard)

    function setWidthServiceCard() {
        if (window.matchMedia("(max-width: 768px)").matches) {
            $serviceCard.width(maxWidth + 20)
        } else {
            $serviceCard.removeAttr("style")
        }
    }
})
$(function () {
    $(".js-user-account-slider").slick({
        arrows: false,
        slidesToShow: 5,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1351,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    });
});

$(function() {
    const $messengerBtn = $(".js-messenger-btn");
    const $messengerTop = $(".js-mesengers-top")
    $messengerBtn.on("click", function() {
        $(this).toggleClass("active")
        $messengerTop.toggleClass("active")
    })
    $(window).on("click", function(e) {
        if (!$messengerBtn.is(e.target) && $messengerBtn.has(e.target).length === 0) {
            $messengerBtn.removeClass("active")
            $messengerTop.removeClass("active")
        }
    })
})
$(function () {
    $('.js-wecan-slider').slick({
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        dots: false,
        infinite: false,
        prevArrow: '<div class="slick__arrow prev"><svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><path d="M36.331 3.278a24.5 24.5 0 10.171 43.354" fill="transparent"/><path d="M14.575 25.424a.6.6 0 010-.848l3.819-3.819a.6.6 0 11.848.849L15.848 25l3.394 3.394a.6.6 0 01-.848.849l-3.819-3.819zm33.758.176H15v-1.2h33.333v1.2z" stroke="transparent" /></svg></div>',
        nextArrow: '<div class="slick__arrow next"><svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><path d="M13.669 3.278a24.5 24.5 0 11-.171 43.354" fill="transparent" /><path d="M35.425 25.424a.6.6 0 000-.848l-3.819-3.819a.6.6 0 10-.848.849L34.152 25l-3.394 3.394a.6.6 0 00.848.849l3.819-3.819zM1.667 25.6H35v-1.2H1.667v1.2z" stroke="transparent" /></svg></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    variableWidth: true,
                }
            },
        ]
    });
})

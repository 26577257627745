$(function () {
    $('.js-select').select2({
        language: {
            noResults: function () {
                return "Ничего не найдено";
            },
        },
    });
    $('.js-select-moded').select2({
        maximumSelectionLength: 1,
        language: {
            noResults: function () {
                return "Ничего не найдено";
            },
            maximumSelected : function (limit) {    
                return 'Доступно только 1 значение';
            }
        },
    });

    $('.js-select-time').select2({
        minimumResultsForSearch: Infinity,
        theme: 'time',
    })
    /*$('.js-select-brand').on("select2:unselect",function(e){
        // $('.js-select-model').select2('data', null);
        $('.js-select-model').val(null).trigger("change");
    });
    $('.js-select-moded').on("select2:opening",function(e){
        $(this).val(null).trigger("change");
    });*/



    function initMask() {
        $('input[name="PHONE"]').attr('type', 'tel');

        var selector = $('input[name="PHONE"]');
        var im = new Inputmask("+ 7 (999) 999-99-99", {
            showMaskOnHover: false,
            "oncomplete": function () {
                // app.maskCompleted = true;
            },
            "onincomplete": function () {
                // app.maskCompleted = false;
            }
        });
        if(selector) {
            im.mask(selector);
        }
        $('body').on('keydown', 'input[name="PHONE"]', function (e) {
            var t = $(this);

            if ((t.val() === t.data('mask') || t.val() === '') && e.key === '8') {
                e.preventDefault();
            }
        });

    }
    initMask();
});
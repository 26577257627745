$(function () {

// Необходимые параметры и функции
    var form_selector = '#file-modal'

    function b64toBlob(dataURI) {
        var
            byteString = atob(dataURI.split(',')[1]),
            ab = new ArrayBuffer(byteString.length),
            ia = new Uint8Array(ab)

        for (var i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ab], {type: 'image/jpeg'})
    }

// // Подключаем перетаскивание файлов
//     $(document).find(form_selector).find('#files_prev ._items').sortable()

// При добавлении файлов
    $(document)
        .find(form_selector)
        .find('#file_add')
        .on('change', function (elem) {
            var oFiles = elem.target.files // Файлы
            // + Перебираем выбранные файлы
            $.each(
                oFiles,
                function (index, elem) {
                    // + Получаем изображение
                    var readerImg = new FileReader()
                    readerImg.onloadend = function () {
                        // + Формируем html
                        var
                            resultHtml = '<div class="_item">'
                            resultHtml += '<img'
                            resultHtml += ' src="' + readerImg.result + '"'
                            resultHtml += ' name="' + elem.name + '"'
                            resultHtml += '/>'
                            resultHtml += '<span class="_remove">x</span>'
                            resultHtml += '</div>'

                        // + Добавляем файлы в область файлов
                        $(document)
                            .find(form_selector)
                            .find('#files_prev ._items')
                            .append(resultHtml)
                    }
                    // + Загружаем изображение в превью
                    readerImg.readAsDataURL(elem)
                }
            )

            return false
        })
    
// Удаление
    $(document)
        .on(
            'click',
            form_selector + ' #files_prev ._items ._item ._remove',
            function () {
                $(this).parents('._item').remove()
            }
        )

// Обложка
    $(document)
        .on(
            'click',
            form_selector + ' #files_prev ._items ._item [name="main_img"]',
            function () {
                $(this).parents('._item').prependTo(form_selector + ' #files_prev ._items')
            }
        )

})

$(function () {

  if (window.matchMedia("(min-width: 768px)").matches) {
    $(".js-products-slider").slick({
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            variableWidth: true,
          },
        },
      ],
    });
  }
});

$(function() {
    $(document).on('keyup', '.js-nav-search-field', function(e){
        e.preventDefault();
        $('.js-nav-search-result').slideDown();
        $('.js-nav-search-reset').fadeIn();
    });

    $(document).on('click', '.js-nav-search-reset', function(e){
        e.preventDefault();
        $('.js-nav-search-result').slideUp();
        $('.js-nav-search-reset').fadeOut();
        $(this).siblings('input').val('');
    });
    $(document).on('click', '.js-nav-toggle', function(e){
        e.preventDefault();
        $(this).siblings('.nav__group-items').slideToggle();
    });

})
$(function () {
    $('.js-reviews').slick({
        dots: false,
        infinite: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        draggable: true,
        prevArrow: '<div class="slick__arrow prev"><svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><path d="M36.331 3.278a24.5 24.5 0 10.171 43.354" fill="transparent"/><path d="M14.575 25.424a.6.6 0 010-.848l3.819-3.819a.6.6 0 11.848.849L15.848 25l3.394 3.394a.6.6 0 01-.848.849l-3.819-3.819zm33.758.176H15v-1.2h33.333v1.2z" stroke="transparent" /></svg></div>',
        nextArrow: '<div class="slick__arrow next"><svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><path d="M13.669 3.278a24.5 24.5 0 11-.171 43.354" fill="transparent" /><path d="M35.425 25.424a.6.6 0 000-.848l-3.819-3.819a.6.6 0 10-.848.849L34.152 25l-3.394 3.394a.6.6 0 00.848.849l3.819-3.819zM1.667 25.6H35v-1.2H1.667v1.2z" stroke="transparent" /></svg></div>',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                }
            },
        ]
    });
    $(document).on('click', '.js-reviews-full', function(e){
        e.preventDefault();
        let content = $(this).closest('.review').get(0).outerHTML;
        $.fancybox.open({
            src  : content,
            type : 'inline',
            opts : {
                afterShow : function( instance, current ) {
                    console.info( 'done!' );
                }
            }
        });
    });
    $(document).find('.review__text').each(function(){
        let text = $(this).text();
        if(text.length<200){
            $(this).addClass('full')
        }
    })
})

$(function () {
    let surveyEl = document.querySelector('.js-survey');
    if (surveyEl && surveyEl.length) {
        let surveyInputs = surveyEl.querySelectorAll('input');
        if (surveyInputs && surveyInputs.length) {
            surveyInputs.forEach(input => {
                input.addEventListener('change', (event) => {
                    event.target.parentNode.classList.add('selected');
                    event.target.parentNode.parentNode.classList.add('survey__list_selected-value');
                    event.target.parentNode.parentNode.classList.add('disabled');
                });
            });
        }
    }
})
$(function () {
    
    let calendarEl = document.querySelector('.js-calendar'),
        thisDay = new Date(),
        nowHourse = thisDay.getHours();

    if (calendarEl) {
        new AirDatepicker('.js-calendar', {
            dateFormat: 'yyyy-MM-dd',
            minDate: thisDay,
            onRenderCell(data) {
                if (data.cellType === 'day') {
                    if (data.date.getDate() < thisDay.getDate() && data.date.getMonth() == thisDay.getMonth()) {
                        return {
                            classes: '-other-month-'
                        }
                    }
                }
            },
            onSelect(data) {
                if (data.date.getDate() === thisDay.getDate()) {
                    $('.new-form [name="TIME"] option').each(function( index ) {
                        var optionHours = Number($(this).attr('data-from'));

                        if (optionHours < nowHourse) {
                            $(this).remove();
                        }
                    });
                } else {
                    if ($('select[name="TIME"] option:nth-child(2)').data('from') !== '00') {
                        for (var i = nowHourse - 1; i >= 0; i--) {
                            var from = (i < 10) ? '0' + i : i;
                            var to = (i < 9) ? '0' + (i + 1) : i + 1;
                            $('.new-form [name="TIME"] option:first').after('<option data-from="' + from + '" value="' + from + '-' + to + '">с ' + from + ':00 до ' + to + ':00</option>');
                        }
                    }
                }
            }
        });
    }

});


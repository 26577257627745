$(function () {
    let settings = {
        mobileFirst: true,
        infinite: false,
        dots: true,
        arrows: false,
        slidesToShow: 1.1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: 'unslick'
            }
        ]
    };

    let mq = window.matchMedia('(min-width: 768px)');

    function handlerWidth(mq) {
        if (!mq.matches) {
            $('.js-rating-carousel').not('.slick-initialized').slick(settings);
        }
    }

    mq.addListener(handlerWidth);
    handlerWidth(mq);

})

$(function () {
    Vue.component('v-select', VueSelect.VueSelect);

    Vue.component('perfect-scrollbar', Vue2PerfectScrollbar.PerfectScrollbar);

    Vue.component('rating', {
        props: ['ratingValue'],
        template: `<div>
                        <div>
                            <div v-for="ratingClass in ratingClasses" v-bind:class="ratingClass" class="online-register__star"></div>
                        </div>
                        <span>{{ ratingValue }}</span>
                    </div>`,
        computed: {
            ratingClasses: function () {
              return [
                    this.ratingValue >= 1 ? 'filled' : 
                        this.ratingValue >= 0.5 ? 'half' : 'empty',
                    this.ratingValue >= 2 ? 'filled' : 
                        this.ratingValue >= 1.5 ? 'half' : 'empty',
                    this.ratingValue >= 3 ? 'filled' : 
                        this.ratingValue >= 2.5 ? 'half' : 'empty',
                    this.ratingValue >= 4 ? 'filled' : 
                        this.ratingValue >= 3.5 ? 'half' : 'empty',
                    this.ratingValue >= 5 ? 'filled' : 
                        this.ratingValue >= 4.5 ? 'half' : 'empty',
              ];
            }
        }
    });
  

    // Vue.use(VueMask.VueMaskPlugin, {
    //   placeholders: {
    //   // define new placeholder
    //   'Я': /[\u0400-\u04FF]/, // cyrillic letter as a placeholder
      
    // }
    // });
  

  const MAX_STEP = 8;

    const app = new Vue({
        el: '#online-register',
        components: { 
            DatePicker,
        },
        data: {
          date: new Date().setHours(0,0,0,0),
          error: [],
          regnumber: '',
          mileage: '',
          phone: localStorage.getItem('phone'),
          name: '',
          surname: '',
          code: '',
          serviceDate: null,
          step: 1,
          brand: null,
          master: [],
          //services: [],
          servicesFiltered: [],
          //service: null,
          cities: [],
          city: null,
          serviceCenters: [],
          serviceCentersFiltered: [],
          serviceCenter: null,
          promo: '',
          preloader: 1,
          times: [],
          time: null,
          timer: 0,
          timerDefault: 60,
          userId: localStorage.getItem('userId'),
        },
        methods: {
          checkStep: function checkStep() {
            let step = this.step;
            let reqInputs = $('.online-register__step' + step + ' [required]');
            let ok = true;
            if (reqInputs.length) {
              for (let i = 0; i < reqInputs.length; i++) {
                let input = $(reqInputs[i]);
                let minLength = $(reqInputs[i]).attr('minlength');
                if ((! input.val() && ! input.hasClass('hasvalue')) || (minLength && input.val().length < minLength)) {
                  input.addClass('error');
                  $('#' + input.attr("data-summary")).addClass('error');
                  input.parent().addClass('error');
                  ok = false;
                }
              }
            }
            if (! ok) {
              return false;
            }       
            
            if (step == 2 && ! this.serviceDate) {
              return false;
            }
            
            return true;
          },
          nextStep:/* async */ function nextStep(scroll = false) {
            
            if (scroll) {
              this.scrollToStart();
            }

            if (! this.checkStep()) {
              return
            }

            let i = this.actionBeforeChangeStep();
            
            if (! i) {
              return
            }

            if (this.step < MAX_STEP) {
              this.step += i;
            }

            this.actionAfterChangeStep();
          },
          actionBeforeChangeStep: function actionBeforeChangeStep() {
            let i = 1;

            if (this.step == 3) {
              if (! this.userId) {
                i = 2;
              }
            } else if (this.step == 4) {
              if (this.userId) {
                if (! /* await */this.createEntry()) {
                  return 0;
                } else {
                  this.getMaster();
                  this.getAddress();
                  i = 4;
                }
              }
            } else if (this.step == 5) {
              if (this.userId) {
                if (/* await */this.sendSms()) {
                  i = 2;
                }
              } else {
                this.registerUser();
              }
            } else if (this.step == 7) {
              if (! /* await */this.checkCode()) {
                return 0;
              }
              if (! /* await */this.createEntry()) {
                return 0;
              } else {
                this.getMaster();
                this.getAddress();
              }
            }
            return i;
          },
          actionAfterChangeStep: function actionAfterChangeStep() {
            if (this.step == 3) {
              this.getDataStepThree();
            }
          },
          prevStep: function prevStep(n = 1) {
            if (this.step > 1) this.step -= n;
          },
          setStep: function setStep(newStep, scrollToTop, evt, check = true) {
            if (check && this.step < newStep && ! this.checkStep()) {
              return
            }
            this.actionBeforeChangeStep()
            this.step = newStep;
            this.actionAfterChangeStep()

            if (scrollToTop) {
              this.scrollToStart();
            }

          },
          scrollToStart: function scrollToStart() {
            if (window.matchMedia("(max-width: 768px)").matches) {
              let topElement = document.getElementById('start');

              window.scrollTo({
                top: window.pageYOffset + topElement.getBoundingClientRect().top - 60,
                behavior: "smooth"
              });

            }
          },
          getDataInitial: function getDataInitial() {
            axios.get('./data/cities.json').then(function (response) {
              if (response.data.error) app.error = response.data.error;
              app.cities = response.data;
            });
            axios.get('./data/serviceСenters.json').then(function (response) {
              if (response.data.error) app.error = response.data.error;
              app.serviceCenters = app.serviceCentersFiltered = response.data;
            });
          },
          getDataStepThree: function getDataStepThree() {
            axios.get('./data/slots.json', {
              params: {
                serviceCenter: app.serviceCenter.code,
                //service: app.service.code,
                brand: app.brand.code,
                serviceDate: app.serviceDate.toLocaleDateString('ru-RU'),
              }
            }).then(function (response) {
              if (response.data.error) {
                app.error = response.data.error;
              } else {
                app.times = response.data;
              }
            });
          },
          /*async */sendSms() {
            localStorage.setItem('phone', app.phone);
            
            if (app.timer === 0) {
              if (! app.userId) {
                return /*await */this.registerUser();
              }
              return /*await */axios.get('./data/sendSms.json', {
                params: {
                  phone: app.phone,
                }
              }).then(function (response) {
                if (response.data.error) {
                  if (response.data.error.code == '29') {
                  } else {
                    app.error = response.data.error;
                  }
                  return false
                } else {
                  app.startTimer();
                  return true;
                }
              });
            }
          },      
          registerUser: function registerUser() {
            localStorage.setItem('phone', app.phone);
            
            return axios.get('./data/registerUser.json', {
              params: {
                phone: app.phone,
              }
            }).then(function (response) {
              if (response.data.error) {
                app.error = response.data.error;
                return false
              } else {
                app.startTimer();
                return true;
              }
            });
          },
          /*async */checkCode() {
            return /*await */axios.get('./data/checkCode.json', {
              params: {
                phone: app.phone,
                name: app.name,
                surname: app.surname,
                code: app.code,
              }
            }).then(function (response) {
              if (response.data.error || (response.data.result && ! response.data.result.user_id)) {
                if (response.data.error.message) {
                  app.error = response.data.error;
                } else {
                  app.error.message = "Ошибка при авторизации";
                }
                return false
              } else {
                if (response.data.result && response.data.result.user_id) {
                  app.userId = response.data.result.user_id;
                  localStorage.setItem('userId', app.userId);
                }
                return true;
              }
            });
          },
          /*async */createEntry() {
            if (app.userId) {
              return /*await */axios.get('./data/createEntry.json', {
                params: {
                  phone: app.phone,
                  userId: app.userId,
                  userName: app.name,
                  brand: app.brand.code,
                  serviceCenter: app.serviceCenter.code,
                  //service: app.service.code,
                  datetime: app.time.code,
                  mcId: app.time.mc_id,
                  useSlot: app.time.useSlot == 'N' ? 'N' : 'Y',
                  regnum: app.regnumber,
                  milage: app.mileage,
                  comms: app.promo
                }
              }).then(function (response) {
                console.log(response);
                console.log(response.data);
                if (response.data.error) {
                  app.error = response.data.error;
                  return false
                } else if(response.data.result.code != "999") {
                  if (response.data.result.message) {
                    app.error.message = response.data.result.message
                  } else if (response.data.error.message) {
                    app.error.message = response.data.error.message
                  } else {
                    app.error.message = 'Ошибка при создании записи';
                  }
                  return false;
                } else {
                  return true;
                }
              });
            } else {
              app.error.message = 'Ошибка при создании записи';
              return false
            }
          },      
          getMaster: function getMaster() {
            axios.get('./data/getMaster.json', {
              params: {
                serviceCenter: app.serviceCenter.code,
                //service: app.service.code,
                brand: app.brand.code,
                mcId: app.time.mc_id,
              }
            }).then(function (response) {
              if (response.data.error) {
                app.error = response.data.error;
              } else {
                app.master = response.data;
              }
            });
          },
          getAddress: function getCenterAddress() {
            axios.get('./data/getCenterAddress.json', {
              params: {
                dcId: app.serviceCenter.code,
              }
            }).then(function (response) {
              if (response.data.error) {
                app.error = response.data.error;
              } else {
                app.serviceCenter.address = response.data;
              }
            });
          },
          startTimer: function startTimer() {
            this.timer = this.timerDefault;
            let interval = setInterval(function(){
              app.timer--;
              if (app.timer == 0) {
                clearInterval(interval);
              }
            }, 1000);
          },
          filterCenters: function filterCenters() {
            let centers = app.serviceCenters;
            
            let cityCode
            if (app.city) {
              cityCode = app.city.code;
            }
            
            let brandCode
            if (app.brand) {
              brandCode = app.brand.code;
            }
            
            this.serviceCentersFiltered = centers.filter(function (center) {
              return center.city == cityCode && center.brands.indexOf(brandCode) != -1;
            });
          },
          logout: function logout() {
            localStorage.removeItem('phone');
            localStorage.removeItem('userId');
            this.code = '';
          },
          disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
          }
        },
        computed: {
            steplistClasses: function () {
              return {
                //'step2': this.step == 2,
                'step2': this.step == 2 || this.step == 3,
                'step3': this.step >= 4,
              }
            },
            mileageMask: function () {
              if (this.mileage.replaceAll(" ", "").length >= 7)
                return "# ### ###";
              else if (this.mileage.replaceAll(" ", "").length === 6)
                return "### ###";
              else if (this.mileage.replaceAll(" ", "").length === 5)
                return "## ###";
              else if (this.mileage.replaceAll(" ", "").length === 4)
                return "# ###";
              else if (this.mileage.replaceAll(" ", "").length === 3)
                return "###";
              else if (this.mileage.replaceAll(" ", "").length === 2)
                return "##";
              else return "#";
            },
          
        },
        watch: {
          // watch: {
          //   $route(to, from) {
          //     this.$refs.scroll.$el.scrollTop = 0;
          //   }
          // },
          city: function () {
            this.serviceCenter = null;
            this.filterCenters();
          },     
          service: function () {
            this.serviceCenter = null;
            this.filterCenters();
          },
          services: function () {
            let brandServiceTypes = this.brand.type;
            this.servicesFiltered = this.services.filter(function (service) {
              return brandServiceTypes.indexOf(service.type) != -1;
            });
          },
          serviceDate: function () {
            if (this.serviceDate && this.date > this.serviceDate) {
              this.serviceDate = '';
            }
          },
          error: function () {
            if (this.error.message) {
              alert(this.error.message);
            }
            console.log(this.error)
            /*$.fancybox.open({
              src: '#sms-error',
              type: 'inline',
              opts: {
                drag: false,
                touch: false
              }
            })*/
          }
        },
        created: function created() {
          $('#online-register').find('.online-register_hide').removeClass('online-register_hide');
          $('.online-register__block_preloader').removeClass('online-register__block_preloader');

          this.getDataInitial();

          this.preloader = 0;
        }
      });
    window.vueApp = app;
});
$(function() {
    $(document).on('click', '.js-header-menu-btn', function(e){
        e.preventDefault();
        $(this).toggleClass('active');
        $('html').toggleClass('disabled');
        $('.js-nav, .js-header').toggleClass('active');
    });

    $(window).on("scroll", function(){
    	let st = window.scrollY;
        if($('.js-header').hasClass('js-inverse-header')){
            if(st > 0) {
                $(".js-header").removeClass("header_trp");
            }else{
                $(".js-header").addClass("header_trp");    
            }
        }      
        
    });
})
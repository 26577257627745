$(function() {

    $(document).on('click', '.js-show-more', function () {
        let $header = $(this);        
        let $parent = $header.parent();
        $parent.toggleClass('show')
        let $content = $header.next();
        $content.slideToggle(500);    
    });

});